/* global $, AirDatepicker */

import 'air-datepicker/air-datepicker.css'
import localeEn from 'air-datepicker/locale/en'

// ! without an id, the air-datepicker js file will not work correctly !
class DesignSystemDatepicker {
  constructor ($datepicker) {
    this.$datepicker = $datepicker
    this.$input = $datepicker.find('.js-datepicker__input-field')

    this.$toggleDisabled = function () {
      this.$input.attr('disabled', !this.$input.attr('disabled'))
    }
  }

  init () {
    const inputValue = this.$input[0].value
    const input = this.$input[0]
    const parent = this.$input[0].parentElement
    const maxDateValue = this.$input.data('date-options-maxdate')
    const minDateValue = this.$input.data('date-options-mindate')
    const minPicker = this.$input.data('date-options-minpicker') ? true : false
    const maxPicker = this.$input.data('date-options-maxpicker') ? true : false

    const airDpInstance = new AirDatepicker(input, {
      locale: localeEn,
      view: this.$input.data('date-options-viewmode'),
      dateFormat: this.$input.data('date-options-format'),
      container: parent,
      autoClose: true,
      firstDay: 1,
      startDate: inputValue ? [inputValue] : [new Date()],
      selectedDates: inputValue ? [inputValue] : false,
      maxDate: maxDateValue,
      minDate: minDateValue,
      onSelect: function onSelect(date) {
        var evt = new CustomEvent('datepickerclick', { detail: { date: date } });
        input.dispatchEvent(evt);
        if (minPicker) {
          var setMinDate = new CustomEvent("setMinDate", {
            detail: {date}
          })
          document.dispatchEvent(setMinDate);
        } else if (maxPicker) {
          var setMaxDate = new CustomEvent("setMaxDate", {
            detail: {date}
          })
          document.dispatchEvent(setMaxDate);
        }
      },
      onRenderCell: function onRenderCell(date) {
        if (airDpInstance.minDate || airDpInstance.maxDate) {
          var maxSelectableDate = airDpInstance.maxDate ? new Date(airDpInstance.maxDate).setDate(airDpInstance.maxDate.getDate()+1) : ""
          var minSelectableDate = airDpInstance.minDate ? new Date(airDpInstance.minDate).setDate(airDpInstance.minDate.getDate()-1) : ""

          if (date.date.toString() == new Date(minSelectableDate).toString() || date.date.toString() == new Date(maxSelectableDate).toString()) {
            return {
              classes: '-range-'
            }
          }
        }
      },
    })
    if (maxPicker) {
      document.addEventListener("setMinDate", (event) => {
        airDpInstance.update({
          minDate: event.detail.date.date.setDate(event.detail.date.date.getDate()+1)
        })
      })
    }
    if (minPicker) {
      document.addEventListener("setMaxDate", (event) => {
         airDpInstance.update({
           maxDate: event.detail.date.date.setDate(event.detail.date.date.getDate()-1)
         })
       })
    }
  }

  handleToggleClick (airDpInstance) {
    airDpInstance.visible ? airDpInstance.hide() : airDpInstance.show()
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-datepicker').each((_, target) => {
    const $datepicker = $(target)

    if (!$datepicker.data('initialized')) {
      $datepicker.data('instance', new DesignSystemDatepicker($datepicker))
      $datepicker.data('instance').init()
      $datepicker.data('initialized', true)
    }
  })
})

export { DesignSystemDatepicker }
