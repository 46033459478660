import _ from 'lodash';
import moment from 'moment';

export default class {
  holidayDaysLeft(fullAllowance, daysCarriedOver, daysInLieu, daysUsed, startDate, endDate) {
    const relativeAllowance = (
      this.daysWorkedYear(
        moment(startDate, 'YYYY-MM-DD'),
        moment(endDate, 'YYYY-MM-DD'),
      ) / this.totalDaysYear()) * _.parseInt(fullAllowance);

    return (
      (
        relativeAllowance
        + _.parseInt(daysCarriedOver)
        + _.parseInt(daysInLieu)
      ) - _.parseInt(daysUsed)
    ).toFixed(2);
  }

  proRatedSalary(daysLeft, salary) {
    return ((daysLeft / this.totalDaysYear()) * salary).toFixed(2);
  }

  daysWorkedText(startDate, leavingDate) {
    const startDateMoment = moment(startDate, 'YYYY-MM-DD');
    const endDateMoment = moment(leavingDate, 'YYYY-MM-DD');

    if (this.daysWorkedYear(startDateMoment, endDateMoment) >= 365) {
      return 'For the full year.';
    }

    return `Pro rated for ${this.daysWorkedYear(startDateMoment, endDateMoment)} days of employment.`;
  }

  yearStart() {
    return moment().startOf('year');
  }

  yearEnd() {
    return moment().endOf('year');
  }

  numberOfDaysBetween(startDate, endDate) {
    if ((startDate === null) || (endDate === null)) {
      return -1;
    }

    return endDate.diff(startDate, 'days') + 1;
  }

  numberOfWorkingDaysBetween(startDate, endDate) {
    return Math.floor(this.numberOfDaysBetween(startDate, endDate) * (5 / 7));
  }

  totalDaysYear() {
    return this.numberOfWorkingDaysBetween(this.yearStart(), this.yearEnd());
  }

  daysWorkedYear(startDate, endDate) {
    return this.numberOfWorkingDaysBetween(startDate, endDate);
  }
}
