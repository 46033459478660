// This file includes shared setup code for all our packs.
import Chart from 'chart.js/auto';
import AirDatepicker from 'air-datepicker';
import appsignal from './appsignal';
import HolidayCalculator from '../hr-tools/holiday-calculator';
import HolidayPayCalculator from '../hr-tools/holiday-pay-calculator';
import tombstone from './tombstone';
import moment from './moment';
import Popper from '../components/popper'
import ClipboardJS from 'clipboard'

// We can not yet move to @rails/ujs because our JavaScript
// relies very heavily on $.ajax and it's callbacks. (especially ajaxComplete)
// We cannot just rename the jQuery ajaxComplete to UJS ajax:complete as they
// behave a bit differently.
// require('@rails/ujs').start();

// jQuery and charlie are magically imported into every JS file via
// webpack.ProvidePlugin. The lines below makes $, jQuery, appsignal, tombstone
// and charlie globally available for use.
window.$ = window.jQuery = jQuery; // eslint-disable-line no-multi-assign
window.appsignal = appsignal;
window.charlie = charlie; // eslint-disable-line no-undef
window.chart = Chart;
global.Chart = Chart;
global.Popper = Popper;
window.tombstone = tombstone;
window.moment = moment;
window.moment.locale('en');
window.AirDatepicker = AirDatepicker;
window.HolidayCalculator = HolidayCalculator;
window.HolidayPayCalculator = HolidayPayCalculator;
window.ClipboardJS = ClipboardJS;

// Mute jQuery migrate warnings before we load it.
jQuery.migrateMute = true;
require('jquery-migrate');

require('jquery-ujs');
