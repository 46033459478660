import _ from 'lodash';
import moment from 'moment';

export default class {
  /**
   * Returns the holiday allowance calculated from the given information.
   *
   * @param {String} daysWorking The number of days, in a week, the employee works.
   * @param {String} daysWorkingWeek The number of days in a working week.
   * @param {String} daysFullTimeAllowance The default leave allowance for a full year.
   * @param {String} startDate The employee's start date.
   * @param {String} leavingDate The employee's end date.
   *
   * @returns Float
   */
  calculate(daysWorking, daysWorkingWeek, daysFullTimeAllowance, startDate, leavingDate) {
    const dW = _.toNumber(daysWorking);
    const dWW = _.parseInt(daysWorkingWeek);
    const dFTA = _.parseInt(daysFullTimeAllowance);
    const fullTimeAllowance = (dW / dWW) * dFTA;
    const totalDaysYear = this.numberOfDaysBetween(this.yearStart(), this.yearEnd());

    if ((this.daysWorkedYear() < 0) || (dW < 0) || (dWW < 0) || (dFTA < 0)
      || (dW > 7) || (dWW > 7) || (dFTA > 365)) {
      return -1;
    }

    return (
      (
        this.daysWorkedYear(
          moment(startDate, 'YYYY-MM-DD'),
          moment(leavingDate, 'YYYY-MM-DD'),
        ) / totalDaysYear
      )
      * fullTimeAllowance
    ).toFixed(2);
  }

  daysWorkedText(startDate, leavingDate) {
    const startDateMoment = moment(startDate, 'YYYY-MM-DD');
    const endDateMoment = moment(leavingDate, 'YYYY-MM-DD');

    if (this.daysWorkedYear(startDateMoment, endDateMoment) >= 365) {
      return 'For the full year.';
    }

    return `Pro rated for ${this.daysWorkedYear(startDateMoment, endDateMoment)} days of employment.`;
  }

  yearStart() {
    return moment().startOf('year');
  }

  yearEnd() {
    return moment().endOf('year');
  }

  daysWorkedYear(startDate = this.yearStart(), endDate = this.yearEnd()) {
    return this.numberOfDaysBetween(startDate, endDate);
  }

  numberOfDaysBetween(startDate, endDate) {
    if ((startDate === null) || (endDate === null)) {
      return -1;
    }

    return endDate.diff(startDate, 'days') + 1;
  }
}
